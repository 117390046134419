import axios from 'axios'
import { setUser } from './userReducer';
import React from "react";
import { createBrowserHistory } from 'history';
import apiSetHeader from "../services/interceptor";
import {store} from "react-notifications-component";

export const customHistory = createBrowserHistory();

export const registration = async (login, password, role, idPublisherAccount, firstName, secondName, middleName) => {
  return axios.post(`/api/Auth/registration/publisher`, {
        login,
        password,
        role,
        idPublisherAccount,
        firstName, secondName, middleName
      });
}

export const entrance = (login, password) => {
  function addNotification(type, message) {
    let titleText = '';
    switch(type){
      case 'danger':
        titleText = 'Ошибка';
        break;
      case 'warning':
        titleText = 'Предупреждение';
        break;  
      default:
        titleText = '';
        break;
    }

    store.addNotification({
      title: titleText,
      message: message,
      type: type,
      insert: 'top',
      container: 'top-right',
      animationIn: [ 'animated', 'fadeIn' ],
      animationOut: [ 'animated', 'fadeOut' ],
      dismiss: { duration: 3000, onScreen: true },
      dismissable: { click: true }
    });
  }
  return async dispatch => {
    try {
      const response = await axios.post(`/api/Auth/login`, {
        login,
        password
      }).then(function (response) {
        dispatch(setUser(response.config.data))
        // console.log(dispatch(setUser(response.config.data)))
        localStorage.setItem('token_isbn', response.data.token);
        apiSetHeader('Authorization', `Bearer ${response.data.token}`)
        customHistory.push('/isbn');

      })
      addNotification('success', 'Авторизация успешна');
      document.location.reload(true);
    } catch (e) {
      addNotification('danger', e.response.data)
    }
  }
}
